import VueMask from 'v-mask';
import money from 'v-money';
import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import App from './App.vue';
import './config/axios';
import './config/vue-toasted';
import vuetify from './plugins/vuetify';
import "./plugins/vuetify-mask.js";
import router from './router';
import store from './store';

import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(money, {precision: 4})
Vue.use(VueMask);
Vue.use(VueSweetalert2);

window.alasql = require('alasql');
window.XLSX = require('xlsx');
alasql['utils'].isBrowserify = false;
alasql['utils'].global.XLSX = XLSX;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
