import axios from 'axios'

//axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('__webtracking')}`
const success = res => res
const error = err => {
    if (401 === err.response.status) {
        window.location = '/login'
    } else {
        return Promise.reject(err)
    }
}

axios.interceptors.response.use(success, error)