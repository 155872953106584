import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        cod_empresa: '',
        name: '',
        token_sisfrete: '',
        token_persys: '',
        integracao: '',
    },
    mutations: {},
    actions: {},
    modules: {}
})
